<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
        <path
            fill-rule="nonzero"
            d="M25.6666667 0 28 2.33333333 16.333 14 28 25.6666667 25.6666667 28 14 16.333 2.33333333 28 0 25.6666667 11.666 14 0 2.33333333 2.33333333 0 14 11.666 25.6666667 0Z"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
